.title {
    font-size: 14px;
    font-family: PopinsSemi, Poppins;
    font-weight: 600;
    color: #2E384E;

    @apply flex justify-between items-center;

    .more {
        width: 52px;
        height: 24px;
        border-radius: 12px;
        text-align: center;
        line-height: 24px;
        border: 1px solid #e8e8e8;

        font-size: 10px;
        font-family: Popins, Poppins-Regular, Poppins;
        font-weight: 400;
        color: #708096;
    }
}

.time {
    padding-top: 6px;
    font-size: 12px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #B8C0CB;
}

.main {
    background: #FFFFFF;
    padding-bottom: 37px;

    .itemBox {
        .item {
            border-bottom: 1px solid #f0f0f0;
            display: flex;
            align-items: center;
            padding: 11px 7px 9px 0;
            justify-content: space-between;

            .sort {
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC, popinsSemi;
                font-weight: 600;
                color: #191919;
                line-height: 22px;
                margin-right: 11px;
            }

            .atr {
                width: 48px;
                height: 48px;
                @apply flex items-center;
            }

            .info {
                // display: flex;
                margin-left: 12px;

                .name {
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC, popinsSemi;
                    font-weight: 600;
                    color: #191919;
                    //   line-height: 20px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 150px;
                }

                .price {
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #708096;
                }

                .count {
                    display: flex;
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC, PingRC;
                    font-weight: 400;
                    color: #708096;
                    //   line-height: 14px;
                }
            }

            .add {
                font-size: 16px;
                font-family: Manrope-Bold, Manrope;
                font-weight: bold;
                color: #191919;
                text-align: right;
            }

            .grow {
                text-align: right;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC, PingRC;
                font-weight: 400;
                color: #708096;
            }
        }
    }
}